import { render, staticRenderFns } from "./cardIsDialog.vue?vue&type=template&id=f860d88c&scoped=true"
import script from "./cardIsDialog.vue?vue&type=script&lang=js"
export * from "./cardIsDialog.vue?vue&type=script&lang=js"
import style0 from "./cardIsDialog.vue?vue&type=style&index=0&id=f860d88c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f860d88c",
  null
  
)

export default component.exports